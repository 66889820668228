import {useParams} from "react-router-dom";
import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import {Button, Container} from "@mui/material";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {handleDownload} from "../commonComponents/fx";


const productDB = {
    "TD-36": {
        name: 'TermoDeck TD-36',
        description: 'El modelo TermoDeck TD-36 está diseñado para proporcionar soluciones estructurales eficientes en la construcción de losas compuestas. Su versatilidad lo convierte en una excelente opción para proyectos que requieren rapidez y eficiencia en el montaje de placas colaborantes.',
        characteristic: 'Espesor reducido, ideal para construcciones ligeras y rápidas. Soporta cargas moderadas y puede utilizarse tanto en sistemas compuestos como en pisos en seco.',
        advantages: [
            'Instalación rápida y sencilla.',
            'Compatible con varios sistemas constructivos.',
            'Resistencia garantizada con certificación de calidad.'
        ]
    },
    "TD-60": {
        name: 'TermoDeck TD-60',
        description: 'El TermoDeck TD-60 es una solución robusta para proyectos que requieren una mayor capacidad de carga y durabilidad. Ideal para aplicaciones en grandes estructuras y construcciones pesadas.',
        characteristic: 'Mayor espesor que el TD-36, lo que le permite soportar cargas más pesadas y ofrecer mayor rigidez estructural.',
        advantages: [
            'Soporta cargas pesadas.',
            'Alta durabilidad en ambientes exigentes.',
            'Certificado de calidad estructural y larga vida útil.'
        ]
    },
    "TD-73": {
        name: 'TermoDeck TD-73',
        description: 'El modelo TermoDeck TD-73 es el más robusto de la serie, diseñado para proyectos de gran escala que requieren la máxima capacidad de carga. Es ideal para aplicaciones industriales y comerciales de gran envergadura.',
        characteristic: 'El TD-73 ofrece la mayor capacidad de carga y resistencia entre los modelos TermoDeck, ideal para grandes infraestructuras.',
        advantages: [
            'Capacidad máxima de carga.',
            'Ideal para grandes proyectos comerciales e industriales.',
            'Alta resistencia y certificación de durabilidad.'
        ]
    },
    "Accesorios": {
        name: 'TermoDeck Accesorios',
        description: 'Complementa tu instalación con accesorios diseñados para mejorar la eficiencia y el rendimiento de nuestras placas colaborantes TermoDeck. Estos accesorios están diseñados para facilitar el montaje y asegurar la durabilidad de la instalación.',
        characteristic: 'Accesorios de alta calidad, diseñados específicamente para adaptarse a los modelos TD-36, TD-60 y TD-73. Incluyen fijaciones, selladores y herramientas de instalación.',
        advantages: [
            'Facilitan el proceso de instalación.',
            'Aseguran la durabilidad y el rendimiento de las placas.',
            'Compatibles con todos los modelos TermoDeck.'
        ]
    }
}


const Product = () => {
    const {name} = useParams();

    const imagePath = `/img/${name}.jpg`
    const productDimension = `/img/medidas-producto/${name}.jpg`
    const data = productDB[name]


    const additionalTools = [
        {
            name: "Certificado del Material",
            icon: "fa-file",
            onClick: () => handleDownload("certificado del material")
        },
        {
            name: "Ficha Técnica",
            icon: "fa-sharp fa-solid fa-copy",
            onClick: () => {
            }
        },
        {
            name: "CAD",
            icon: "fa-sharp fa-solid fa-desktop",
            onClick: () => {
            }
        },
        {
            name: "Manual de Instalación",
            icon: "fa-solid fa-wrench",
            onClick: () => {
            }
        },
        {
            name: "Manual de Diseño",
            icon: "fa-solid fa-book",
            onClick: () => handleDownload("manual de diseño")
        },
        {
            name: "Herramientas Web de Diseño",
            icon: "fa-solid fa-calculator",
            onClick: () => {
            }
        },
    ]

    return (
        <Container maxWidth="lg">

            <Box sx={{flexGrow: 1}}>
                <Grid container spacing={6}>
                    <Grid size={12}>
                        <h1 className="text-secondary mt-4">{name}</h1>
                    </Grid>

                    <Grid size={{xs: 12, md: 4}}>


                        <Box sx={{flexGrow: 1}}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid size={12}>
                                    <img src={imagePath} alt={name} style={{maxWidth: '100%'}}/>
                                </Grid>
                                <Grid size={12}>
                                    <b>{data.name}</b>
                                    <div align="left">
                                        <b>Descripción:</b>
                                        <br/>
                                        {data.description}
                                    </div>
                                </Grid>
                            </Grid>
                        </Box>


                    </Grid>

                    <Grid size={{xs: 12, md: 8}}>
                        <div align="left">
                            <div>
                                <b>Características:</b>
                                <br/>
                                {data.characteristic}
                            </div>


                            {(name !== "Accesorios") && (
                                <div>
                                    <b>Medidas:</b>
                                    <br/>
                                    <img src={productDimension} alt={name} style={{maxWidth: '100%'}}/>
                                    <div align="right">[milímetros]</div>
                                </div>
                            )}


                            <div>
                                <b>Descargas:</b>

                                <Box flexGrow="1">
                                    <Grid container spacing={2} align="center" alignItems="center">
                                        {additionalTools.map(
                                            ({name, icon, onClick}, index) => (
                                                <Grid key={index} size={{xs: 12, md: 4}}>

                                                    <Button variant="outlined" fullWidth onClick={onClick}>
                                                        <Box flexGrow="1">

                                                            <Grid container align="center" alignItems="center">

                                                                <Grid size={4}>
                                                                    <i className={`fa ${icon} fa-2x text-primary`}></i>
                                                                </Grid>

                                                                <Grid size={8} alignItems="center">
                                                                    {/*<CenterDiv>*/}
                                                                    <h6>{name}</h6>
                                                                    {/*</CenterDiv>*/}
                                                                </Grid>

                                                            </Grid>
                                                        </Box>
                                                    </Button>


                                                </Grid>
                                            )
                                        )}
                                    </Grid>
                                </Box>

                            </div>
                            <div>
                                <b>Ventajas:</b>
                                <br/>
                                {
                                    data.advantages.map(
                                        (item, index) => (
                                            <div key={index}>
                                                <CheckBoxIcon
                                                    fontSize="large"
                                                    color="primary"
                                                />
                                                {item}
                                            </div>)
                                    )
                                }
                            </div>
                        </div>
                    </Grid>

                </Grid>
            </Box>
        </Container>
    )
}
export default Product