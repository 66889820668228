import './App.css';
import {BrowserRouter as Router, Routes, Route, Link, useParams} from 'react-router-dom';
import Product from "./pages/Product";
import info from "./info";
import Contact from "./pages/Contact";
import {handleDownload} from "./commonComponents/fx";


const {address, email, phone} = info

const navItems = {
    "Productos": [
        {name: "TD-36", link: "/product/TD-36"},
        {name: "TD-60", link: "/product/TD-60"},
        {name: "TD-73", link: "/product/TD-73"},
        {name: "Accesorios", link: "/product/Accesorios"},
    ],
    "Herramientas de Diseño": [
        {name: "Pisos en Seco", link: "/dryFloor"},
        {name: "Encofrado Temporal", link: "/deckForm"},
        {name: "Sistemas Compuestos", link: "/compositeDeck"},
    ],
    "Literatura": [
        {name: "Manual de Diseño", link: "/literature/manual-de-diseño"},
        {name: "Manual de Instalación", link: "/literature/manual-de-instalación"},
    ],
    "Información Adicional": [
        {name: "Videos", link: "/additionalInfo/videos"},
    ],
    "Contactanos": [
        {name: "Contactanos", link: "/contact"},
    ]
}


const WebStructure = ({children}) => {
    const TopBar = () => (
        <div className="container-fluid bg-dark py-2 d-none d-md-flex">
            <div className="container">
                <div className="d-flex justify-content-between topbar">
                    <div className="top-info">
                        <small className="me-3 text-white-50">
                            <a href="#"><i className="fas fa-map-marker-alt me-2 text-secondary"></i></a>
                            {address}
                        </small>
                        <small className="me-3 text-white-50">
                            <a href="#"><i className="fas fa-envelope me-2 text-secondary"></i></a>
                            {email}
                        </small>
                    </div>
                    <div id="note" className="text-secondary d-none d-xl-flex"><small>Expertos en Placa
                        Colaborante</small>
                    </div>
                    <div className="top-link">
                        <a href="" className="bg-light nav-fill btn btn-sm-square rounded-circle">
                            <i className="fab fa-facebook-f text-primary"></i>
                        </a>
                        <a href="" className="bg-light nav-fill btn btn-sm-square rounded-circle">
                            <i className="fab fa-twitter text-primary"></i>
                        </a>
                        <a href="" className="bg-light nav-fill btn btn-sm-square rounded-circle">
                            <i className="fab fa-instagram text-primary"></i>
                        </a>
                        <a href="" className="bg-light nav-fill btn btn-sm-square rounded-circle me-0">
                            <i className="fab fa-linkedin-in text-primary"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )

    const NavBar = () => {

        return (
            <div className="container-fluid bg-primary">
                <div className="container">
                    <nav className="navbar navbar-dark navbar-expand-lg py-0">

                        <Link to={"/"} className="navbar-brand">
                            <h1 className="text-white fw-bold d-block">Termo<span className="text-secondary">Deck</span>
                            </h1>
                        </Link>

                        <button type="button" className="navbar-toggler me-0" data-bs-toggle="collapse"
                                data-bs-target="#navbarCollapse">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse bg-transparent" id="navbarCollapse">
                            <div className="navbar-nav ms-auto mx-xl-auto p-0">

                                <div className="nav-item dropdown">
                                    <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                                        Productos
                                    </a>
                                    <div className="dropdown-menu rounded">
                                        <Link
                                            className="dropdown-item"
                                            to="/product/TD-36"
                                        >
                                            TD-36
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            to="/product/TD-60"
                                        >
                                            TD-60
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            to="/product/TD-73"
                                        >
                                            TD-73
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            to="/product/Accesorios"
                                        >
                                            Accesorios
                                        </Link>
                                    </div>
                                </div>

                                <div className="nav-item dropdown">
                                    <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                                        Herramientas de Diseño
                                    </a>
                                    <div className="dropdown-menu rounded">
                                        <Link
                                            className="dropdown-item"
                                            to={`https://civilcalc.com/termodeck/dryFloor`}
                                        >
                                            Pisos en Seco
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            to={`https://civilcalc.com/termodeck/deckFormWork`}
                                        >
                                            Encofrado Temporal
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            to={`https://civilcalc.com/termodeck/compositeDeck`}
                                        >
                                            Sistema Compuesto
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            to={`https://civilcalc.com/termodeck/wallThickness`}
                                        >
                                            Muro TermoMetal
                                        </Link>
                                    </div>
                                </div>

                                <div className="nav-item dropdown">
                                    <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                                        Literatura
                                    </a>
                                    <div className="dropdown-menu rounded">
                                        <button
                                            className="dropdown-item"
                                            onClick={() => handleDownload("manual de diseño")}
                                        >
                                            Manual de Diseño
                                        </button>
                                        <button
                                            className="dropdown-item"
                                            onClick={() => {
                                            }}
                                        >
                                            Manual de Instalación
                                        </button>
                                    </div>
                                </div>

                                <div className="nav-item dropdown">
                                    <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                                        Información Adicional
                                    </a>
                                    <div className="dropdown-menu rounded">
                                        <button
                                            className="dropdown-item"
                                            onClick={() => {
                                            }}
                                        >
                                            Videos
                                        </button>
                                    </div>
                                </div>

                                <div className="nav-item">
                                    <Link to="/contact" className="nav-link">
                                        Contacto
                                    </Link>
                                </div>


                            </div>
                        </div>
                        <div className="d-none d-xl-flex flex-shirink-0">
                            <div id="phone-tada" className="d-flex align-items-center justify-content-center me-4">
                                <a href="" className="position-relative animated tada infinite">
                                    <i className="fa fa-phone-alt text-white fa-2x"></i>
                                    <div className="position-absolute" style={{top: '-7px', left: '20px'}}>
                                        <span><i className="fa fa-comment-dots text-secondary"></i></span>
                                    </div>
                                </a>
                            </div>
                            <div className="d-flex flex-column pe-4 border-end">
                                <span className="text-white-50">¿Tienes preguntas?</span>
                                <span className="text-secondary">Tlf: {phone}</span>
                            </div>
                            <div className="d-flex align-items-center justify-content-center ms-4 ">
                                <a href="#"><i className="bi bi-search text-white fa-2x"></i> </a>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        )
    }

    const Footer = () => (
        <div className="container-fluid footer bg-dark wow fadeIn" data-wow-delay=".3s">
            <div className="container pt-5 pb-4">
                <div className="row g-5">
                    <div className="col-lg-3 col-md-6">
                        <a href="index.html">
                            <h1 className="text-white fw-bold d-block">Termo<span className="text-secondary">Deck</span>
                            </h1>
                        </a>
                        <p className="mt-4 text-light">
                            En Termodeck, ofrecemos placas colaborantes de alta calidad, asegurando eficiencia y
                            resistencia
                            en proyectos de construcción.
                        </p>
                        <div className="d-flex hightech-link">
                            <a href="" className="btn-light nav-fill btn btn-square rounded-circle me-2"><i
                                className="fab fa-facebook-f text-primary"></i></a>
                            <a href="" className="btn-light nav-fill btn btn-square rounded-circle me-2"><i
                                className="fab fa-twitter text-primary"></i></a>
                            <a href="" className="btn-light nav-fill btn btn-square rounded-circle me-2"><i
                                className="fab fa-instagram text-primary"></i></a>
                            <a href="" className="btn-light nav-fill btn btn-square rounded-circle me-0"><i
                                className="fab fa-linkedin-in text-primary"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <a href="#" className="h3 text-secondary">Secciones</a>
                        <div className="mt-4 d-flex flex-column short-link">
                            {Object.keys(navItems).map(
                                (item, index) => (
                                    <a key={`footer-item-${index}`} href="" className="mb-2 text-white">
                                        <i className="fas fa-angle-right text-secondary me-2"></i>
                                        {item}
                                    </a>
                                )
                            )}
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <a href="#" className="h3 text-secondary">Nosotros</a>
                        <div className="mt-4 d-flex flex-column help-link">
                            <a href="" className="mb-2 text-white">
                                <i className="fas fa-angle-right text-secondary me-2"></i>
                                Herramientas
                            </a>
                            <a href="" className="mb-2 text-white">
                                <i className="fas fa-angle-right text-secondary me-2"></i>
                                Participación en Proyectos
                            </a>
                            <a href="" className="mb-2 text-white">
                                <i className="fas fa-angle-right text-secondary me-2"></i>
                                Catálogo
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <a href="#" className="h3 text-secondary">Contacto</a>
                        <div className="text-white mt-4 d-flex flex-column contact-link">
                            <a href="#" className="pb-3 text-light border-bottom border-primary">
                                <i className="fas fa-map-marker-alt text-secondary me-2"></i>
                                {address}
                            </a>
                            <a href="#" className="py-3 text-light border-bottom border-primary">
                                <i className="fas fa-phone-alt text-secondary me-2"></i>
                                {phone}
                            </a>
                            <a href="#" className="py-3 text-light border-bottom border-primary">
                                <i className="fas fa-envelope text-secondary me-2"></i>
                                {email}
                            </a>
                        </div>
                    </div>
                </div>
                <hr className="text-light mt-5 mb-4"/>
                <div className="row">
                    <div className="col-md-6 text-center text-md-start">
                <span className="text-light"><a href="#" className="text-secondary"><i
                    className="fas fa-copyright text-secondary me-2"></i>TermoDeck</a>, Todos los Derechos Reservados</span>
                    </div>
                </div>
            </div>
        </div>
    )

    return (
        <div className="d-flex flex-column" style={{minHeight: "100vh"}}>
            <TopBar/>
            <NavBar/>

            {children}

            <Footer/>
        </div>
    )
};

const HomeContent = () => {

    const CarouselMain = () => (
        <div className="container-fluid px-0">
            <div id="carouselId" className="carousel slide" data-bs-ride="carousel">
                <ol className="carousel-indicators">
                    <li data-bs-target="#carouselId" data-bs-slide-to="0" className="active" aria-current="true"
                        aria-label="First slide"></li>
                    <li data-bs-target="#carouselId" data-bs-slide-to="1" aria-label="Second slide"></li>
                </ol>
                <div className="carousel-inner" role="listbox">
                    <div className="carousel-item active">
                        <img src="img/carousel-1.jpg" className="img-fluid" alt="First slide"/>
                        <div className="carousel-caption">
                            <div className="container carousel-content">
                                <h6 className="text-secondary h4 animated fadeInUp">Mejor Placa Colaborante</h6>
                                <h1 className="text-white display-1 mb-4 animated fadeInRight">
                                    Productos de Calidad
                                </h1>
                                <p className="mb-4 text-white fs-5 animated fadeInDown">
                                    Los mejores productos placas colaborantes de calidad.
                                </p>
                                <a href="" className="me-2">
                                    <button type="button"
                                            className="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn1 animated fadeInLeft">
                                        Productos
                                    </button>
                                </a>
                                <a href="" className="ms-2">
                                    <button type="button"
                                            className="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn2 animated fadeInRight">
                                        Asistencia Técnica
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src="img/carousel-2.jpg" className="img-fluid" alt="Second slide"/>
                        <div className="carousel-caption">
                            <div className="container carousel-content">
                                <h6 className="text-secondary h4 animated fadeInUp">Innovación Real</h6>
                                <h1 className="text-white display-1 mb-4 animated fadeInLeft">
                                    Presencia en Proyectos
                                </h1>
                                <p className="mb-4 text-white fs-5 animated fadeInDown">
                                    Participación en una serie de proyectos
                                </p>
                                <a href="" className="me-2">
                                    <button type="button"
                                            className="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn1 animated fadeInLeft">
                                        Ver Proyectos
                                    </button>
                                </a>
                                <a href="" className="ms-2">
                                    <button type="button"
                                            className="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn2 animated fadeInRight">
                                        Reseñas
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselId"
                        data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselId"
                        data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    )

    const Experience = () => (
        <div className="container-fluid bg-secondary py-5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 wow fadeIn" data-wow-delay=".1s">
                        <div className="d-flex counter">
                            <h1 className="me-3 text-primary counter-value">99</h1>
                            <h5 className="text-white mt-1">Proyectos Culminados</h5>
                        </div>
                    </div>
                    <div className="col-lg-4 wow fadeIn" data-wow-delay=".3s">
                        <div className="d-flex counter">
                            <h1 className="me-3 text-primary counter-value">25000</h1>
                            <h5 className="text-white mt-1">m2 de area techada</h5>
                        </div>
                    </div>
                    <div className="col-lg-4 wow fadeIn" data-wow-delay=".5s">
                        <div className="d-flex counter">
                            <h1 className="me-3 text-primary counter-value">3</h1>
                            <h5 className="text-white mt-1">Tipos de sección de la placa</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

    const ConstructionSystems = () => (
        <div className="container-fluid py-5">
            <div className="container">
                <h1 className="text-primary mb-4">Uso en Sistemas Constructivos</h1>

                <p>
                    <strong>TermoDeck</strong> ofrece soluciones eficientes para cada una de las aplicaciones de la
                    placa
                    colaborante en la
                    construcción moderna. Nuestra placa está diseñada para adaptarse a distintos sistemas
                    constructivos,
                    brindando versatilidad y rendimiento tanto en aplicaciones de pisos en seco como en sistemas
                    compuestos
                    de concreto. A continuación, detallamos sus principales usos para optimizar cualquier proyecto
                    constructivo.
                </p>

                <div className="mb-4">

                    <h2 className="text-secondary mb-4" style={{textAlign: 'left'}}>
                        Pisos en Seco
                    </h2>

                    <div className="row g-5">
                        <div className="col-md-6 col-sm-12 wow fadeIn d-flex align-items-center justify-content-center"
                             data-wow-delay=".3s">
                            <img src="img/deck-use-1.jpg" className="img-fluid rounded mx-auto" alt=""/>
                        </div>
                        <div className="col-md-6 col-sm-12 wow fadeIn d-flex align-items-center justify-content-center"
                             data-wow-delay=".3s">
                            <p>
                                <strong>TermoDeck</strong> funciona como un elemento estructural independiente, sin la
                                necesidad
                                de
                                verter concreto. Es ideal para proyectos en los que se requiere un piso rápido y ligero,
                                proporcionando soporte inmediato.
                            </p>
                        </div>
                    </div>

                </div>

                <div className="mb-4">

                    <h2 className="text-secondary mb-4" style={{textAlign: 'right'}}>
                        Sistemas Compuestos de Concreto
                    </h2>

                    <div className="row g-5">
                        <div
                            className="col-lg-3 col-md-6 col-sm-12 wow fadeIn d-flex align-items-center justify-content-center"
                            data-wow-delay=".3s">
                            <p>
                                <strong>Encofrado temporal: </strong> Durante la fase de construcción,
                                la <strong>TermoDeck </strong>sirve como encofrado para sostener el concreto hasta que
                                este
                                fragüe y adquiera su resistencia.
                            </p>
                        </div>
                        <div
                            className="col-lg-3 col-md-6 col-sm-12 wow fadeIn d-flex align-items-center justify-content-center"
                            data-wow-delay=".3s">
                            <img src="img/deck-use-2.jpg" className="img-fluid rounded mx-auto" alt=""/>
                        </div>
                        <div
                            className="col-lg-3 col-md-6 col-sm-12 wow fadeIn d-flex align-items-center justify-content-center"
                            data-wow-delay=".3s">
                            <p>
                                <strong>Material del sistema compuesto: </strong>Una vez endurecido el
                                concreto, <strong>TermoDeck </strong>pasa a formar parte integral del sistema
                                estructural
                                compuesto, trabajando conjuntamente con el concreto para soportar las cargas. Aquí es
                                crucial diseñar tanto la placa como el concreto para maximizar la eficiencia del sistema
                                compuesto.
                            </p>
                        </div>
                        <div
                            className="col-lg-3 col-md-6 col-sm-12 wow fadeIn d-flex align-items-center justify-content-center"
                            data-wow-delay=".3s">
                            <img src="img/deck-use-3.jpg" className="img-fluid rounded mx-auto" alt=""/>
                        </div>
                    </div>

                </div>


            </div>
        </div>
    )

    const SteelDecks = () => (
        <div className="container-fluid py-5">
            <div className="container">
                <h1 className="text-primary mb-4">Modelos TermoDeck</h1>

                <div className="container-fluid px-0">
                    <div id="deckCarouselID" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner" role="listbox">
                            <div className="carousel-item active carousel-item-no-opacity">
                                <h1 className="text-secondary mb-4">TD-36</h1>
                                <img src={`img/TD-36.jpg`} className="img-fluid" alt="Second slide"
                                     style={{maxWidth: '75%', objectFit: 'contain'}}/>
                            </div>
                            <div className="carousel-item carousel-item-no-opacity">
                                <h1 className="text-secondary mb-4">TD-60</h1>
                                <img src={`img/TD-60.jpg`} className="img-fluid" alt="Second slide"
                                     style={{maxWidth: '75%', objectFit: 'contain'}}/>
                            </div>
                            <div className="carousel-item carousel-item-no-opacity">
                                <h1 className="text-secondary mb-4">TD-73</h1>
                                <img src={`img/TD-73.jpg`} className="img-fluid" alt="Second slide"
                                     style={{maxWidth: '75%', objectFit: 'contain'}}/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#deckCarouselID"
                                data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#deckCarouselID"
                                data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>

            </div>
        </div>
    )

    const AdditionalTools = () => {
        const items = [
            {
                name: "Certificado del Material",
                description: "Ofrecemos placas colaborantes fabricadas con acero estructural de alta resistencia, respaldadas por certificados de calidad que garantizan su durabilidad y seguridad en proyectos constructivos",
                icon: "fa-file",
                buttonLabel: "Descargar",
                fx: () => handleDownload("certificado del material")
            },
            {
                name: "Fichas Técnicas",
                description: "Accede a fichas técnicas detalladas con todas las especificaciones de nuestra placa colaborante, desde sus propiedades mecánicas hasta recomendaciones de uso en diferentes tipos de estructuras.",
                icon: "fa-sharp fa-solid fa-copy",
                buttonLabel: "Ver Más",
                fx: () => {
                }
            },
            {
                name: "Dibujos CAD",
                description: "Proporcionamos dibujos CAD personalizados para facilitar la integración de nuestra placa colaborante en el diseño estructural de tus proyectos, asegurando precisión y eficiencia en la construcción.",
                icon: "fa-sharp fa-solid fa-desktop",
                buttonLabel: "Ver Más",
                fx: () => {
                }
            },
            {
                name: "Manual de Instalación",
                description: "Este manual te guiará paso a paso en el proceso de instalación de la placa colaborante, optimizando tiempos y garantizando un montaje seguro y efectivo.",
                icon: "fa-solid fa-wrench",
                buttonLabel: "Ver Más",
                fx: () => {
                }
            },
            {
                name: "Manual de Diseño",
                description: "Nuestro manual de diseño proporciona todas las pautas necesarias para incorporar la placa colaborante en estructuras, desde cálculos de carga hasta detalles técnicos, asegurando un diseño óptimo y seguro",
                icon: "fa-solid fa-book",
                buttonLabel: "Descargar",
                fx: () => handleDownload("manual de diseño")
            },
            {
                name: "Herramientas Web de Diseño",
                description: "Utiliza nuestras herramientas de diseño en línea para calcular y simular el comportamiento estructural de la placa colaborante, facilitando la toma de decisiones en tus proyectos.",
                icon: "fa-solid fa-calculator",
                buttonLabel: "Ver Más",
                fx: () => {
                }
            },
        ]


        return (
            <div className="container-fluid services py-5">
                <div className="container">
                    <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s"
                         style={{maxWidth: '600px'}}>
                        <h5 className="text-primary">Herramientas Adicionales</h5>
                        <h1>Comfort con el Diseño e Instalación con TermoDeck</h1>
                    </div>
                    <div className="row g-5 services-inner">

                        {items.map(
                            ({name, description, icon, buttonLabel, fx}, index) => (
                                <div key={`service-${index}`} className="col-md-6 col-lg-4 wow fadeIn"
                                     data-wow-delay=".3s">
                                    <div className="services-item bg-light">
                                        <div className="p-4 text-center services-content">
                                            <div className="services-content-icon">
                                                <i className={`fa ${icon} fa-7x mb-4 text-primary`}></i>
                                                <h4 className="mb-3">{name}</h4>
                                                <p className="mb-4">{description}</p>
                                                <a onClick={fx}
                                                   className="btn btn-secondary text-white px-5 py-3 rounded-pill">
                                                    {buttonLabel}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        )}

                    </div>

                </div>
            </div>
        )
    }

    const Projects = () => {
        const items = [
            {
                title: "Puente Peatonal Modular",
                description: "Diseño modular de puente peatonal rápido."
            },
            {
                title: "Puente Peatonal Modular",
                description: "Descripción: Infraestructura comercial de diseño contemporáneo."
            },
            {
                title: "Autopista Elevada Urbana",
                description: "Construcción de autopista elevada en zona urbana."
            },
            {
                title: "Centro Logístico Industrial",
                description: "Complejo industrial para logística y distribución."
            },
            {
                title: "Estadio Multifuncional",
                description: "Estadio con capacidad adaptable para eventos deportivos."
            },
            {
                title: "Terminal de Aeropuerto Moderno",
                description: "Terminal aéreo eficiente con diseño contemporáneo."
            },
        ]
        return (
            <div className="container-fluid project py-5">
                <div className="container">
                    <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s"
                         style={{maxWidth: '600px'}}>
                        <h5 className="text-primary">Participación en Proyectos</h5>
                        <h1>Presentes en los Mayores Proyectos de Infraestructura</h1>
                    </div>
                    <div className="row g-5">

                        {items.map(
                            ({title, description}, index) => (
                                <div key={`project-${index}`} className="col-md-6 col-lg-4 wow fadeIn"
                                     data-wow-delay=".3s">
                                    <div className="project-item">
                                        <div className="project-img">
                                            <img src={`img/project-${index + 1}.jpg`}
                                                 className="img-fluid w-100 rounded"
                                                 alt=""/>
                                            <div className="project-content">
                                                <a href="#" className="text-center">
                                                    <h4 className="text-secondary">{title}</h4>
                                                    <p className="m-0 text-white">{description}</p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        )}

                    </div>
                </div>
            </div>
        )
    }

    const DesignAppSection = () => (
        <div className="container-fluid py-5">
            <div className="container pt-5">
                <h1 className="text-primary mb-4">Herramientas de Diseño</h1>
                <div className="row g-5">
                    <div className="col-lg-7 col-md-6 col-sm-12 wow fadeIn d-flex align-items-center"
                         data-wow-delay=".5s">

                        <div className="row">

                            <div className="col-12">
                                <p>
                                    Las herramientas web de Termodeck facilitan el diseño de losas compuestas, ayudando
                                    a
                                    mejorar la
                                    eficiencia del proyecto y reducir costos. Con nuestras herramientas en línea, podrás
                                    seleccionar
                                    el modelo de placa colaborante más adecuado y rentable para tu proyecto, incluyendo
                                    nuestros
                                    tres modelos: TD-36, TD-60 y TD-73.


                                </p>
                            </div>

                            <div className="col-12 py-3">
                                <a
                                    onClick={
                                        () => {
                                        }
                                    }
                                    className="btn btn-secondary text-white px-5 py-3 rounded-pill">
                                    Ver Más
                                </a>
                            </div>

                        </div>

                    </div>
                    <div
                        className="col-lg-5 col-md-6 col-sm-12 wow fadeIn d-flex align-items-center justify-content-center"
                        data-wow-delay=".3s">
                        <img src="img/appTool.jpg" className="img-fluid rounded mx-auto" alt=""/>
                    </div>
                </div>
            </div>
        </div>
    )


    return (
        <div>
            <CarouselMain/>
            <Experience/>
            <ConstructionSystems/>
            <SteelDecks/>
            <AdditionalTools/>
            <Projects/>
            <DesignAppSection/>
            <Contact/>
        </div>
    )
}

const Section = () => {
    const {name} = useParams();
    return (
        <div>Producto {name}</div>
    )
}

const App = () => {
    return (
        <div className="App">
            <Router>
                <WebStructure>
                    <Routes>
                        <Route path="/" element={<HomeContent/>}/>
                        <Route path="/product/:name" element={<Product/>}/>
                        <Route path="/designTool/:name" element={<Section/>}/>
                        <Route path="/literature/:name" element={<Section/>}/>
                        <Route path="/additionalInfo/:name" element={<Section/>}/>
                        <Route path="/contact" element={<Contact/>}/>
                    </Routes>
                </WebStructure>
            </Router>
        </div>
    );
}

export default App;
